import React from 'react';

import Layout from 'components/layout/Layout';
import {
  AlignmentLink,
  DevelopmentalProgressionsLink
} from 'components/VisualToolLink';

const MyComponent: React.FC = () => {
  return (
    <Layout>
      <h1>Compare State Standards</h1>

      <p>
        <a href="/state-standards/">View All State Standards</a>
      </p>

      <div className="VisualToolLink-index-wrapper">
        <AlignmentLink displayStyle="index" />
        <DevelopmentalProgressionsLink displayStyle="index" />
      </div>
    </Layout>
  );
};

export default MyComponent;
